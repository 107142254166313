import Vue from "vue";

export const sharedState = Vue.observable({
  commandPrefill: "",
  commandPosition: { x: 0, y: 0 },
  commandCursorPosition: 0,
  autofillPosition: { x: 0, y: 0 },
  isShowingAutofill: false,
  isShowingCommand: false,
  isShowingQuillIdea: false,
});

export const getCoordinatesAtCursor = (editor) => {
  const cursorPos = editor.selection.from;
  const coords = editor.view.coordsAtPos(cursorPos);

  return coords;
};

export const sanitizeHtml = (html) => {
  html = html.replace(/^<html[^>]*>|<\/html>$/g, "");
  html = html.replace(/^<head[^>]*>.*?<\/head>/gim, "");
  html = html.replace(/^<body[^>]*>|<\/body>$/g, "");

  return html;
};

export const getCleanHTML = (editor) => {
  let html = editor.getHTML();

  html = html.replace(/^<html[^>]*>|<\/html>$/g, "");
  html = html.replace(/^<head[^>]*>.*?<\/head>/gim, "");
  html = html.replace(/^<body[^>]*>|<\/body>$/g, "");

  return html;
};
