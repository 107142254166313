<template>
  <editor-content ref="editorContent" :editor="editor" class="tiptap-paragraph" />
</template>

<script>
import { Editor, EditorContent } from "tiptap";
import { Placeholder } from "tiptap-extensions";

import { getCleanHTML } from "@/helpers/tiptap/utils";

export default {
  name: "TipTapParagraph",
  components: { EditorContent },
  props: {
    id: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Tell your story...",
    },
    value: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    editor: null,

    isFocused: false,
  }),
  created() {
    const { placeholder } = this;
    let { value: content } = this;

    this.editor = new Editor({
      extensions: [
        new Placeholder({
          emptyEditorClass: "is-editor-empty",
          emptyNodeClass: "is-empty",
          emptyNodeText: placeholder,
        }),
      ],
      content,
    });
  },
  mounted() {
    this.editor.on("update", this.handleUpdate);

    this.editor.view.dom.addEventListener("focus", this.handleFocus);
    this.editor.view.dom.addEventListener("blur", this.handleBlur);
  },
  beforeDestroy() {
    this.editor.off("update", this.handleUpdate);
    this.editor.view.dom.removeEventListener("focus", this.handleFocus);
    this.editor.view.dom.removeEventListener("blur", this.handleBlur);

    this.editor.destroy();
  },

  methods: {
    handleUpdate() {
      const { editor } = this;

      let content = getCleanHTML(editor);

      this.$emit("update:value", content);
      this.$emit("input", content);
    },

    handleFocus() {
      this.isFocused = true;
    },
    handleBlur() {
      this.isFocused = false;
    },
  },
};
</script>

<style lang="scss">
.tiptap-paragraph {
  .is-empty::before {
    position: absolute;
    content: attr(data-empty-text) !important;
    color: #a9a9a9;
    pointer-events: none;
  }

  .ProseMirror:focus {
    outline: none;
    border: none;
  }

  p {
    margin-top: 10px;
  }

  blockquote {
    margin: 20px 0;
    padding: 10px 20px;
    border-left: 5px solid #ccc;
    font-style: italic;
    quotes: "\201C""\201D""\2018""\2019";
  }

  blockquote:before {
    content: open-quote;
    font-size: 2em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
    color: #ccc;
  }

  blockquote:after {
    content: close-quote;
  }

  blockquote p {
    display: inline;
    font-size: 1em;
  }
}

.tiptap.is-sending {
  .is-empty {
    display: none !important;
  }
}
</style>
